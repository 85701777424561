<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">floor plans: full list</div>
        <div class="module-preview--contents">
            <div class="font-600 text-lg">{{ getValue('sectionTitle') }}</div>
            <p class="mt-4 text-sm" :inner-html.prop="getValue('description')"></p>
        </div>
    </div>     
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                imageIds: []
            }
        },
        computed: {
            ...mapGetters({
                floorplans: 'floorplans/getFloorplans'
            }),
        },
        created() {
            this.imageIds = JSON.parse(this.getValue('images'));
        }
    }
</script>
<style scoped>
    .module-preview--contents{
        display:block;
        grid-gap: unset;
    }
    .module-preview--contents p{
        max-width: 100ch;
    }    
</style>